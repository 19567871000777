import { matchesUA } from "browserslist-useragent";
import memoize from "memoize-one";
import browserslist from "@root/.browserslistrc";

export const isBrowserSupported = memoize((): boolean | undefined => {
  try {
    const browsers = browserslist.split("\n").filter(Boolean);
    return matchesUA(navigator.userAgent, {
      path: " ",
      allowHigherVersions: true,
      browsers,
    });
  } catch (error) {
    console.error("Failed to check if browser is supported", error);
    return undefined;
  }
});
