import type { Object } from "ts-toolbelt";
import type { ReferralProperties } from "./helpers/referrals";
import type {
  DepositsRole,
  FinancialProductType,
  RiskTier,
  UserRole,
  SegmentV4,
  SubsegmentV4,
} from "@/__generated__/globalTypes";
import type { LegacyUserRole as RoleString } from "@/data/sharedTypes";
import type {
  EventSchemaMap as AppEventSchemaMap,
  MetadataSchemaMap as AppMetadataSchemaMap,
} from "@/domains/App/analytics";
import {
  AnalyticsEvents as AppAnalyticsEvents,
  AnalyticsMetadata as AppAnalyticsMetadata,
} from "@/domains/App/analytics";
import type { ControllerEventsMap } from "@/domains/App/components/Controller/sharedTypes";
import type { EventSchemaMap as BillPayEventSchemaMap } from "@/domains/BillPay/analytics";
import { AnalyticsEvents as BillPayAnalyticsEvents } from "@/domains/BillPay/analytics";
import { CardAnalyticsEvents } from "@/domains/Card/analytics";
import type { CardEventSchemaMap } from "@/domains/Card/analytics";
import { CashAnalyticsEvents } from "@/domains/Cash/analytics";
import type { CashEventSchemaMap } from "@/domains/Cash/analytics";
import type { EventSchemaMap as CommunicationsEventSchemaMap } from "@/domains/Communications/analytics";
import { AnalyticsEvents as CommunicationsAnalyticsEvents } from "@/domains/Communications/analytics";
import type { EventSchemaMap as HREventSchemaMap } from "@/domains/HR/analytics";
import { AnalyticsEvents as HRAnalyticsEvents } from "@/domains/HR/analytics";
import type { EventSchemaMap as IntegrationsEventSchemaMap } from "@/domains/Integrations/analytics";
import { AnalyticsEvents as IntegrationsAnalyticsEvents } from "@/domains/Integrations/analytics";
import { KycAnalyticsEvents } from "@/domains/Kyc/analytics";
import type { KycEventSchemaMap } from "@/domains/Kyc/analytics";
import type { LimitExperienceEventSchemaMap } from "@/domains/LimitExperience/analytics";
import { LimitExperienceAnalyticsEvents } from "@/domains/LimitExperience/analytics";
import type { OnboardingEventSchemaMap } from "@/domains/Onboarding/analytics";
import { OnboardingAnalyticsEvents } from "@/domains/Onboarding/analytics";
import type {
  PrimitivesEventSchemaMap,
  PrimitivesMetadataSchemaMap,
} from "@/domains/Primitives/shared/analytics";
import {
  PrimitivesAnalyticsEvents,
  PrimitivesAnalyticsMetadata,
} from "@/domains/Primitives/shared/analytics";
import type { EventSchemaMap as ReferralsEventSchemaMap } from "@/domains/Referrals/analytics";
import { AnalyticsEvents as ReferralsAnalyticsEvents } from "@/domains/Referrals/analytics";
import type { RiskEventSchemaMap } from "@/domains/Risk/analytics";
import { RiskAnalyticsEvents } from "@/domains/Risk/analytics";
import type { EventSchemaMap as SpendEventSchemaMap } from "@/domains/Spend/analytics";
import { AnalyticsEvents as SpendAnalyticsEvents } from "@/domains/Spend/analytics";
import type { SupportEventSchemaMap } from "@/domains/Support/analytics";
import { SupportAnalyticsEvents } from "@/domains/Support/analytics";
import type { EventSchemaMap as TeamEventSchemaMap } from "@/domains/Team/analytics";
import { AnalyticsEvents as TeamAnalyticsEvents } from "@/domains/Team/analytics";
import type { EventSchemaMap as TravelEventSchemaMap } from "@/domains/Travel/analytics";
import { AnalyticsEvents as TravelAnalyticsEvents } from "@/domains/Travel/analytics";
import type { EventSchemaMap as TrustEventSchemaMap } from "@/domains/Trust/analytics";
import { AnalyticsEvents as TrustAnalyticsEvents } from "@/domains/Trust/analytics";
import type { EventSchemaMap as UnderwritingEventSchemaMap } from "@/domains/Underwriting/analytics";
import { AnalyticsEvents as UnderwritingAnalyticsEvents } from "@/domains/Underwriting/analytics";
import { AnalyticsEvents as VendorAnalyticsEvents } from "@/domains/Vendor/analytics";
import type { EventSchemaMap as VendorEventSchemaMap } from "@/domains/Vendor/analytics";
import { TwoFactorAuthAnalyticsEvents } from "@/features/2FA/analytics";
import type { TwoFactorAuthEventSchemaMap } from "@/features/2FA/analytics";
import type { ActivateUserEventSchemaMap } from "@/features/ActivateUser/analytics";
import { ActivateUserAnalyticsEvents } from "@/features/ActivateUser/analytics";
import type { AdaEventSchemaMap } from "@/features/Ada/analytics";
import { AdaAnalyticsEvents } from "@/features/Ada/analytics";
import { ArfiAnalyticsEvents } from "@/features/Arfi/analytics";
import type { ArfiEventSchemaMap } from "@/features/Arfi/analytics";
import { AnalyticsEvents as AuthenticationAnalyticsEvents } from "@/features/Authentication/analytics";
import type { EventSchemaMap as AuthenticationEventSchemaMap } from "@/features/Authentication/analytics";
import { AnalyticsEvents as BulkUserManagementAnalyticsEvents } from "@/features/BulkUserManagement/sharedTypes";
import type { EventSchemaMap as BulkUserManagementEventSchemaMap } from "@/features/BulkUserManagement/sharedTypes";
import type { ComplianceEventSchemaMap } from "@/features/Compliance/analytics";
import { ComplianceAnalyticsEvents } from "@/features/Compliance/analytics";
import { AnalyticsEvents as DROPAnalyticsEvents } from "@/features/DROP/shared/analytics";
import type { EventSchemaMap as DROPEventSchemaMap } from "@/features/DROP/shared/analytics";
import type { HelpshiftEventSchemaMap } from "@/features/Helpshift/helpers/analytics";
import { HelpshiftAnalyticsEvents } from "@/features/Helpshift/helpers/analytics";
import { KycControllerAnalyticsEvents } from "@/features/KycController/analytics";
import type { KycControllerEventSchemaMap } from "@/features/KycController/analytics";
import type { EventSchemaMap as MobileUpsellEventSchemaMap } from "@/features/MobileUpsell/sharedTypes";
import { AnalyticsEvents as MobileUpsellAnalyticsEvents } from "@/features/MobileUpsell/sharedTypes";
import { PolicyAnalyticsEvents } from "@/features/PolicyState/analytics";
import type { PolicyEventSchemaMap } from "@/features/PolicyState/analytics";
import { AnalyticsEvents as SelectFundingSourceAnalyticsEvents } from "@/features/SelectFundingSource/sharedTypes";
import type { EventSchemaMap as SelectFundingSourceEventSchemaMap } from "@/features/SelectFundingSource/sharedTypes";
import { AnalyticsEvents as SessionTimeoutModalAnalyticsEvents } from "@/features/SessionTimeoutModal/analytics";
import type { EventSchemaMap as SessionTimeoutModalEventsSchemaMap } from "@/features/SessionTimeoutModal/analytics";
import type { SpendLimitToggleRequirementSchemaMap } from "@/features/SpendLimitRequirementToggle/analytics";
import { SpendLimitToggleRequirementEvents } from "@/features/SpendLimitRequirementToggle/analytics";
import { StepUpMfaAnalyticsEvents } from "@/features/StepUpMfa/analytics";
import type { StepUpMfaEventSchemaMap } from "@/features/StepUpMfa/analytics";
import type { EventSchemaMap as SupportHubEventSchemaMap } from "@/features/SupportHub/analytics";
import { AnalyticsEvents as SupportHubAnalyticsEvents } from "@/features/SupportHub/analytics";
import { AnalyticsEvents as SandboxAnalyticsEvents } from "@/modules/Sandbox/analytics";
import type { EventSchemaMap as SandboxEventSchemaMap } from "@/modules/Sandbox/analytics";
import type { AccountManagementEventSchemaMap } from "@/routes/AccountManagement/analytics";
import { AccountManagementAnalyticsEvents } from "@/routes/AccountManagement/analytics";
import { RewardsAnalyticsEvents } from "@/routes/Rewards/analytics";
import type { RewardsEventSchemaMap } from "@/routes/Rewards/analytics";
/**
 * DO NOT ADD DOMAIN SPECIFIC ANALYTICS EVENTS TO THIS ENUM
 *
 * This enum is for globally shared analytics events. Add domain specific
 * events to the analytics.ts file located at the root level of the domain.
 */
export enum SharedAnalyticsEvents {
  TourTouchpointActionTaken = "tour.touchpoint_action.taken",
}

/**
 * To add a new set of domain specific events, you must:
 *
 * 1. Add the domain events schema map to AllEventsSchemaMap
 * 2. Add the domain events to AllAnalyticsEvents
 * 3. Add to the tests in src/features/Analytics/helpers/analyticsEvents.test.ts
 */

export type AllEventsSchemaMap = AppEventSchemaMap &
  BillPayEventSchemaMap &
  VendorEventSchemaMap &
  CardEventSchemaMap &
  CashEventSchemaMap &
  HREventSchemaMap &
  IntegrationsEventSchemaMap &
  OnboardingEventSchemaMap &
  ReferralsEventSchemaMap &
  RewardsEventSchemaMap &
  RiskEventSchemaMap &
  KycEventSchemaMap &
  SpendEventSchemaMap &
  TeamEventSchemaMap &
  TravelEventSchemaMap &
  TrustEventSchemaMap &
  UnderwritingEventSchemaMap &
  PrimitivesEventSchemaMap &
  SupportEventSchemaMap &
  MobileUpsellEventSchemaMap &
  SelectFundingSourceEventSchemaMap &
  ControllerEventsMap &
  DROPEventSchemaMap &
  HelpshiftEventSchemaMap &
  AdaEventSchemaMap &
  AuthenticationEventSchemaMap &
  ActivateUserEventSchemaMap &
  BulkUserManagementEventSchemaMap &
  CommunicationsEventSchemaMap &
  SessionTimeoutModalEventsSchemaMap &
  LimitExperienceEventSchemaMap &
  TwoFactorAuthEventSchemaMap &
  StepUpMfaEventSchemaMap &
  ComplianceEventSchemaMap &
  KycControllerEventSchemaMap &
  PolicyEventSchemaMap &
  ArfiEventSchemaMap &
  SandboxEventSchemaMap &
  SupportHubEventSchemaMap &
  AccountManagementEventSchemaMap &
  SpendLimitToggleRequirementSchemaMap;

export const AllAnalyticsEvents = {
  ...SharedAnalyticsEvents,
  ...AppAnalyticsEvents,
  ...BillPayAnalyticsEvents,
  ...VendorAnalyticsEvents,
  ...CardAnalyticsEvents,
  ...CashAnalyticsEvents,
  ...HRAnalyticsEvents,
  ...IntegrationsAnalyticsEvents,
  ...OnboardingAnalyticsEvents,
  ...ReferralsAnalyticsEvents,
  ...RewardsAnalyticsEvents,
  ...RiskAnalyticsEvents,
  ...KycAnalyticsEvents,
  ...SpendAnalyticsEvents,
  ...TeamAnalyticsEvents,
  ...TravelAnalyticsEvents,
  ...TrustAnalyticsEvents,
  ...UnderwritingAnalyticsEvents,
  ...PrimitivesAnalyticsEvents,
  ...SupportAnalyticsEvents,
  ...MobileUpsellAnalyticsEvents,
  ...SelectFundingSourceAnalyticsEvents,
  ...DROPAnalyticsEvents,
  ...HelpshiftAnalyticsEvents,
  ...AdaAnalyticsEvents,
  ...AuthenticationAnalyticsEvents,
  ...ActivateUserAnalyticsEvents,
  ...BulkUserManagementAnalyticsEvents,
  ...CommunicationsAnalyticsEvents,
  ...SessionTimeoutModalAnalyticsEvents,
  ...LimitExperienceAnalyticsEvents,
  ...TwoFactorAuthAnalyticsEvents,
  ...StepUpMfaAnalyticsEvents,
  ...ComplianceAnalyticsEvents,
  ...KycControllerAnalyticsEvents,
  ...PolicyAnalyticsEvents,
  ...ArfiAnalyticsEvents,
  ...SandboxAnalyticsEvents,
  ...SupportHubAnalyticsEvents,
  ...AccountManagementAnalyticsEvents,
  ...SpendLimitToggleRequirementEvents,
} as const;

export type AllAnalyticsEventsKeys = keyof AllEventsSchemaMap;

export type EventPayloadSchema<T extends AllAnalyticsEventsKeys> =
  Required<AllEventsSchemaMap>[T];

export type AllMetadataSchemaMap = AppMetadataSchemaMap &
  PrimitivesMetadataSchemaMap;

export const AllAnalyticsMetadata = {
  ...PrimitivesAnalyticsMetadata,
  ...AppAnalyticsMetadata,
};

export type AllAnalyticsMetadataKeys = keyof AllMetadataSchemaMap;

export type MetadataPayloadSchema<T extends AllAnalyticsMetadataKeys> =
  Required<AllMetadataSchemaMap>[T];

export type TrackEventProps<TEventName extends AllAnalyticsEventsKeys> =
  // schema definition has no keys (or is an empty object)
  keyof EventPayloadSchema<TEventName> extends never
    ? {
        eventName: TEventName;
        eventProps?: {};
      }
    : // schema definition exists but they are all optionals keys
      Object.RequiredKeys<EventPayloadSchema<TEventName>> extends never
      ? {
          eventName: TEventName;
          eventProps?: {};
        }
      : {
          eventName: TEventName;
          eventProps: EventPayloadSchema<TEventName> & {
            disableAmplitudeDestinationOnSegment?: boolean;
          };
        };

export const EventDestinations = [
  "segment",
  "datadogRum",
  "logRocket",
] as const;

export type EventDestination = (typeof EventDestinations)[number];

export type TrackFunctionType = <TEventName extends AllAnalyticsEventsKeys>(
  eventName: TEventName,
  eventProperties?: TrackEventProps<TEventName>["eventProps"],
  callback?: () => void,
  destinations?: readonly EventDestination[],
  enabledSegmentDestinations?: Array<keyof IntegrationsFlags>,
) => void;

export type UserProperties = ReferralProperties & {
  accountStatus?: string | null;
  approvedBlueprintAtOnboarding?: string | null;
  cashActivationDatetime?: string;
  companyHasDepositsAccount?: boolean;
  companyId?: string;
  companyLegalName?: string | null;
  companyName?: string | null;
  ct?: string | null;
  customerAccountId?: string;
  customerUserId?: string;
  delegatorId?: string;
  depositsRole?: DepositsRole | null;
  email?: string | null;
  financialProductType?: FinancialProductType | null;
  firstName?: string | null;
  firstTransactionCleared?: boolean;
  firstTransactionCompleted?: boolean;
  hasAdvancedExpenseManagementEnabled?: boolean;
  hasMultiEntityEnabled?: boolean;
  hasPayableBillAccess?: boolean;
  hasProductApplication?: boolean;
  hasBasicReimbursementsEnabled?: boolean;
  billPayOnEmpowerMigrated?: boolean;
  helpshiftAuthToken?: string;
  id?: string;
  initialMarketSegment?: string | null;
  insertedAt?: string;
  invitedDepostsAdmin?: boolean;
  isInitialApplicant?: boolean;
  isInternalSignup?: boolean;
  isInvitedDepositsCashAndCardUser?: boolean;
  isManager?: boolean;
  isEmpowerLight?: boolean;
  lastName?: string | null;
  name?: string;
  productApplicationSubmittedDatetime?: string;
  productTier?: string;
  riskTier?: RiskTier | null;
  role?: RoleString | null;
  signupDatetime?: string;
  status?: string | null;
  attributionToken?: string | null;
  userRole?: UserRole | null;
  isSandbox?: boolean | null;
  segmentV4?: SegmentV4 | null;
  subsegmentV4?: SubsegmentV4 | null;
  partOfCohorts?: string[] | null;
  hasPartnershipIntegration?: boolean;
  partnerName?: string | null;
};

export type MarketoCustomFields = {
  implementationCompleteScreen?: boolean;
  email?: string | null;
};

export type NonNullUserProperties = {
  [K in keyof UserProperties]: NonNullable<UserProperties[K]>;
};

export type IntegrationsFlags = {
  "Amazon S3": boolean;
  Amplitude: boolean;
  "Amplitude Actions": boolean;
  "Google Tag Manager": boolean;
  "Google Universal Analytics": boolean;
  "Google Analytics V4": boolean;
  Mutiny: boolean;
  snowflake: boolean;
  "Marketo V2": boolean;
};
